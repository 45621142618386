import { IChoiceGroupOption } from "@fluentui/react";

export const userClassOptionName = "userClass";
export const showLogsOptionName = "showLogsPanel";
export const selectedChannelErrorColumnsName = "selectedChannelErrorColumns";
export const selectedSellinErrorColumnsName = "selectedSellinErrorColumns";

export const getUserTags = () => {
  let userClassName = userClassOptions.find((u) => u.key === localStorage[userClassOptionName])?.text;

  return userClassName && [userClassName];
};

export const userClassOptions: IChoiceGroupOption[] = [
  { key: "C", text: "Channel" },
  { key: "S", text: "Sellin" },
];

export const defaultChannelErrorColumns: string[] = [
  "ReportEndDate",
  "Originality",
  "InvoiceDate",
  "InvoiceNumber",
  "MatchedPartNumber",
  "Currency",
  "SellUnitPrice",
  "SellPriceExtended",
  "CalculatedUnitPrice",
  "PricingMethod",
  "SoldQty",
  "OnHandQty",
  "StockTransferQty",
  "LocationReturnedQty",
  "OnOrderQty",
  "OrderEnteredQty",
  "InTransitQty",
  "CommittedQty",
  "InFloatQty",
  "BackOrderQty",
  "VendorReturnQty",
  "ReportingType",
  "ReportingValue",
  "EndUserFlag",
  "BillToCountry",
  "BillToLocation",
  "BillToPostalCode",
  "ShipToCountry",
  "ShipToLocation",
  "ShipToPostalCode",
  "SellFromCountry",
  "SellFromLocation",
  "SellFromPostalCode",
  "ShipFromCountry",
  "ShipFromLocation",
  "ShipFromPostalCode",
];

export const additionalChannelErrorColumns: string[] = [
  "MarchedTo",
  "RecordID",
  "TransmissionRowID",
  "SourceProductDesc",
  "SourceProductID",
  "SourceSKU",
  "SourceUPC",
];

export const channelErrorColumns: string[] = defaultChannelErrorColumns.concat(additionalChannelErrorColumns);

export const defaultSellinErrorColumns: string[] = [
  "RevenueUSD",
  "Revenue",
  "GKProgramCode",
  "GKItemID",
  "TerritoryCode",
  "SubsidiaryCode",
  "CurrencyCode",
  "BillingSystemCode",
  "RecordTypeCode",
  "ChannelCode",
  "BillTo",
  "ShipTo",
  "InvoiceDate",
  "Qty",
  "PartNumber",
  "AdjustedSOLineItemID",
  "TransferCOGS",
  "CompanyCode",
  "BillDocTypeCode",
  "SalesOfficeCode",
  "PlantCode",
  "ReasonCode",
  "ItemName",
  "CodeName",
];

export const additionalSellinErrorColumns: string[] = ["RecordID", "TransmissionRowID"];

export const sellinErrorColumns: string[] = defaultSellinErrorColumns.concat(additionalSellinErrorColumns);
